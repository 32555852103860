
import enums from '@/core/enums/enums';
import Api from '@/core/Api';
import {defineComponent, reactive, inject, ref} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {ILogin} from "@/core/interfaces/ILogin";
import router from "@/router";
import DataStore from "@/store/composition/dataStore";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: 'Login',
  components: {},

  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');
    const emailValid = ref(true);
    const passwordValid = ref(true);

    const groupNameValid = ref(true);
    const groupUsernameValid = ref(true);
    const groupUserPasswordValid = ref(true);

    const state = reactive({
      email: '',
      password: '',

      groupName: '',
      groupUsername: '',
      groupUserPassword: '',
    });

    const onLogin = async () => {
      emailValid.value = state.email.length > 0;
      passwordValid.value = state.password.length > 0;

      if (!emailValid.value || !passwordValid.value) {
        return
      }

      const data: ILogin = {username: state.email, password: state.password};
      return await executeLogin(data);
    }

    const onGroupUserLogin = async () => {
      groupNameValid.value = state.groupName.length > 0;
      groupUsernameValid.value = state.groupUsername.length > 0;
      groupUserPasswordValid.value = state.groupUserPassword.length > 0;

      if (!groupNameValid.value || !groupUsernameValid.value || !groupUserPasswordValid.value) {
        return
      }

      const data: ILogin = {
        username: state.groupName + '@@@@' + state.groupUsername,
        password: state.groupUserPassword
      };
      return await executeLogin(data, 'groupLogin');
    }

    const executeLogin = async (loginData: ILogin, type = 'regularLogin') => {

      await Api.login(loginData)
          .then((res: any) => {

            // parse login state
            dataStore.tokenGetters.tokenComputed.value = window.localStorage.getItem(enums.ACCESS_TOKEN);

            // parse token data
            dataStore.state.username = dataStore.tokenGetters.getTokenUsername();

            // parse roles from token
            dataStore.state.userRoles = dataStore.tokenGetters.getTokenUserRoles()

            const getUserData = async () => {
              await Api.getUserData(dataStore);
            }
            getUserData();

            // navigate to main page
            router.push({path: '/'});
          })
          .catch((err: any) => {
            if (type === 'regularLogin') {
              emailValid.value = false;
              passwordValid.value = false;

              groupNameValid.value = true;
              groupUsernameValid.value = true;
              groupUserPasswordValid.value = true;
            } else {
              emailValid.value = true;
              passwordValid.value = true;

              groupNameValid.value = false;
              groupUsernameValid.value = false;
              groupUserPasswordValid.value = false;
            }
          })
    }

    return {
      state, dataStore, onLogin, enums, emailValid, passwordValid,
      groupNameValid, groupUsernameValid, groupUserPasswordValid, onGroupUserLogin,
    }
  }
});
