<template>
  <div class="view login container-fluid" style="height: calc(100vh - 56px);">

    <div class="container-sm mt-5" style="max-width: 400px;">

      <div class="row" id="login-box">
        <div class="col p-4" style="background: #ecf2a2">

          <h4 class="mb-3">Logi sisse</h4>

          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                   v-model="state.email" :class="{ 'is-invalid': !emailValid}" @keyup.enter="onLogin">
            <label for="floatingInput">Email</label>
            <div class="invalid-feedback">Vigane email</div>
          </div>

          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
                   v-model="state.password" :class="{ 'is-invalid': !passwordValid}" @keyup.enter="onLogin">
            <label for="floatingPassword">Parool</label>
            <div class="invalid-feedback">Vigane parool</div>
          </div>

          <button type="submit" class="btn btn-outline-secondary mb-3" @click="onLogin">Logi sisse</button>


          <hr class="my-4">

          <div class="d-flex justify-content-evenly">
            <h5 class="mb-3">Grupi liige?</h5>
            <i class="bi bi-caret-down-square float-end" data-bs-toggle="collapse" style="font-size: 1.2rem;"
               data-bs-target="#groupLoginCollapse" aria-expanded="false" aria-controls="groupLoginCollapse">
              Ava
            </i>
            <!--            </div>-->
          </div>

          <div class="collapse" id="groupLoginCollapse">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput3" placeholder="name@example.com"
                     v-model="state.groupName" :class="{ 'is-invalid': !groupNameValid}"
                     @keyup.enter="onGroupUserLogin">
              <label for="floatingInput3">Grupp</label>
              <div class="invalid-feedback">Vigane grupp</div>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput4" placeholder="Password"
                     v-model="state.groupUsername" :class="{ 'is-invalid': !groupUsernameValid}"
                     @keyup.enter="onGroupUserLogin">
              <label for="floatingInput4">Kasutajanimi</label>
              <div class="invalid-feedback">Vigane kasutajanimi</div>
            </div>

            <div class="form-floating mb-3">
              <input type="password" class="form-control" id="floatingInput5" placeholder="Password"
                     v-model="state.groupUserPassword" :class="{ 'is-invalid': !groupUserPasswordValid}"
                     @keyup.enter="onGroupUserLogin">
              <label for="floatingInput5">Parool</label>
              <div class="invalid-feedback">Vigane kasutaja parool</div>
            </div>

            <button type="submit" class="btn btn-outline-secondary mb-3" @click="onGroupUserLogin">Logi sisse</button>
          </div>

          <hr class="my-4">

          <h5 class="mb-3">Uus kasutaja?</h5>

          <router-link to="/register">
            <button type="submit" class="btn btn-outline-secondary mb-3">Loo konto</button>
          </router-link>

        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import enums from '@/core/enums/enums';
import Api from '@/core/Api';
import {defineComponent, reactive, inject, ref} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {ILogin} from "@/core/interfaces/ILogin";
import router from "@/router";
import DataStore from "@/store/composition/dataStore";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: 'Login',
  components: {},

  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');
    const emailValid = ref(true);
    const passwordValid = ref(true);

    const groupNameValid = ref(true);
    const groupUsernameValid = ref(true);
    const groupUserPasswordValid = ref(true);

    const state = reactive({
      email: '',
      password: '',

      groupName: '',
      groupUsername: '',
      groupUserPassword: '',
    });

    const onLogin = async () => {
      emailValid.value = state.email.length > 0;
      passwordValid.value = state.password.length > 0;

      if (!emailValid.value || !passwordValid.value) {
        return
      }

      const data: ILogin = {username: state.email, password: state.password};
      return await executeLogin(data);
    }

    const onGroupUserLogin = async () => {
      groupNameValid.value = state.groupName.length > 0;
      groupUsernameValid.value = state.groupUsername.length > 0;
      groupUserPasswordValid.value = state.groupUserPassword.length > 0;

      if (!groupNameValid.value || !groupUsernameValid.value || !groupUserPasswordValid.value) {
        return
      }

      const data: ILogin = {
        username: state.groupName + '@@@@' + state.groupUsername,
        password: state.groupUserPassword
      };
      return await executeLogin(data, 'groupLogin');
    }

    const executeLogin = async (loginData: ILogin, type = 'regularLogin') => {

      await Api.login(loginData)
          .then((res: any) => {

            // parse login state
            dataStore.tokenGetters.tokenComputed.value = window.localStorage.getItem(enums.ACCESS_TOKEN);

            // parse token data
            dataStore.state.username = dataStore.tokenGetters.getTokenUsername();

            // parse roles from token
            dataStore.state.userRoles = dataStore.tokenGetters.getTokenUserRoles()

            const getUserData = async () => {
              await Api.getUserData(dataStore);
            }
            getUserData();

            // navigate to main page
            router.push({path: '/'});
          })
          .catch((err: any) => {
            if (type === 'regularLogin') {
              emailValid.value = false;
              passwordValid.value = false;

              groupNameValid.value = true;
              groupUsernameValid.value = true;
              groupUserPasswordValid.value = true;
            } else {
              emailValid.value = true;
              passwordValid.value = true;

              groupNameValid.value = false;
              groupUsernameValid.value = false;
              groupUserPasswordValid.value = false;
            }
          })
    }

    return {
      state, dataStore, onLogin, enums, emailValid, passwordValid,
      groupNameValid, groupUsernameValid, groupUserPasswordValid, onGroupUserLogin,
    }
  }
});
</script>

<style scoped lang="scss">

#login-box {
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.5);
}

</style>
